import IconSVG from "../../styles/svg-icons";
import { Preloader } from "./Preloader";

interface Props {
    isExportLoad: boolean;
    handleExport: () => void;
    disabled?: boolean;
}

export default function ExportButton({
    isExportLoad,
    handleExport,
    disabled = false,
}: Props) {
    return (
        <Preloader
            small
            fullScreen={false}
            inProgress={isExportLoad}
            text="Exporting..."
        >
            <button
                disabled={disabled}
                onClick={handleExport}
                className="btn-link"
            >
                <IconSVG name="export" width="16" height="16" />
                Export
            </button>
        </Preloader>
    );
}

