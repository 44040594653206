import { useMemo } from 'react';
import { PlotMouseEvent } from 'plotly.js';
import { cloManagersChartBar } from '../../../../../constants/cloManagers/chartBar';
import { mainConfig, Plot } from '../../../../common';
import { arrayUtils, chartUtils } from '../../../../../utils';
import { constants } from '../../../../../constants';
import { ChartView } from '../../../../../types/dashboard/AmrChart';
import { CloManagerDealStats } from '../../../../../types/clo-managers/CloManagerDealStats';
import { TransactionType } from '../../../../../types/amr-pipeline/enums/TransactionType';

interface Props {
    chartDiv?: string;
    data: CloManagerDealStats[];
    onClick?: (comoanyReferenceName: string, transactionType: string) => void;
}

const RequiredTransactionTypes = [
    TransactionType.newIssue,
    TransactionType.refi,
    TransactionType.reset,
] as const;

type NewIssueRefiReset = typeof RequiredTransactionTypes[number];

const ChartLegends = [
    'New Issue',
    'Refinancing',
    'Reset',
];

type ByTransactionTypeGroup = Record<NewIssueRefiReset, any>;
type TransactionTypeCompanyCodeGroup = {
    transactionType: TransactionType;
    companyCode: string | null;
    elements: CloManagerDealStats[];
};

export function CloManagerArrangerStatsBarChart({
    chartDiv,
    data,
    onClick,
}: Props) {
    const { label, tickColor, tickFont, zeroLineColor, margin, fontSize, barColors } = cloManagersChartBar;

    const groupedByTransactionType = useMemo(() => {
        // Filter out data that can influence on sorting
        const withoutAmrDeals = data.filter(x => !!x.transactionType && x.transactionType !== TransactionType.amr)

        const groupedByCompanyCode = arrayUtils.groupBy(
            withoutAmrDeals,
            (row: CloManagerDealStats) => row.companyCode
        );

        // Sort by sum of all deals and get ordered list of company codes
        const sortedCompanies = Array.from(groupedByCompanyCode.values())
            .sort((a, b) => arrayUtils.sum(b, value => value.numberOfDeals) - arrayUtils.sum(a, value => value.numberOfDeals))
            .map(([x]) => x.companyCode);

        // Go through each company and collect groups aggregated by company code and transaction type
        const groupedByCompanyCodeAndType = sortedCompanies.reduce((acc: TransactionTypeCompanyCodeGroup[], companyCode) => {
            // Iterate through exactly all required transaction types here to
            // accumulate empty arrays, when there is no data for particular transaction
            // type of a current company. Otherwise it may cause `shifted` bars with a
            // higher summary. Plotly doesn't give a shit about missing transaction types in some places,
            // and shifts them further in chart.
            const groups = RequiredTransactionTypes.map(transactionType => {
                return {
                    transactionType,
                    companyCode,
                    elements: withoutAmrDeals.filter(x =>
                        x.companyCode === companyCode
                        && x.transactionType === transactionType
                    )
                };
            });

            return [...acc, ...groups];
        }, []);

        return groupedByCompanyCodeAndType.reduce((acc: ByTransactionTypeGroup, group) => {
            const { transactionType, companyCode, elements } = group;
            const [firstElement] = elements;

            // Still need this to preserve types integrity
            if (!transactionType || transactionType === TransactionType.amr) {
                return acc;
            }

            const {
                x = [],
                y = [],
                customdata = []
            } = acc[transactionType];

            // For empty arrays return zero - it won't render, but bar will
            // be placed in correct place
            if (!firstElement) {
                return {
                    ...acc,
                    [transactionType]: {
                        x: [...x, companyCode || constants.emptyPlaceholder],
                        y: [...y, 0],
                        customdata: [...customdata, undefined],
                    },
                };
            }

            const { companyReferenceName } = firstElement;

            const numberOfDeals = arrayUtils.sum(elements, (value: CloManagerDealStats) => value.numberOfDeals);

            return {
                ...acc,
                [transactionType]: {
                    x: [...x, companyCode || constants.emptyPlaceholder],
                    y: [...y, numberOfDeals],
                    customdata: [...customdata, {
                        companyReferenceName,
                        transactionType,
                    }],
                },
            };
        }, {
            [TransactionType.newIssue]: {},
            [TransactionType.refi]: {},
            [TransactionType.reset]: {},
        });
    }, [data]);

    const onBarClick = (e: PlotMouseEvent) => {
        if (!onClick) {
            return;
        }

        const point = e && e.points && e.points[0];

        if (!point) {
            return;
        }

        const {
            companyReferenceName,
            transactionType,
        } = point.customdata as any;

        onClick(companyReferenceName, transactionType);
    };

    const getChartData = () => {
        return Object.values(groupedByTransactionType).map(({ x, y, customdata }, index) => ({
            x,
            y,
            type: 'bar',
            hoverinfo: 'text',
            textposition: 'none',
            name: ChartLegends[index],
            customdata,
            text: y?.map((value: number) => `${ChartLegends[index]}: ${value}`),
            marker: { color: barColors[index] },
        }));
    };

    const chartLayout = {
        showlegend: false,
        barmode: ChartView.Stacked,
        autosize: true,
        hovermode: 'closest',
        hoverlabel: {
            bordercolor: label.bordercolor,
            bgcolor: label.bgColor,
            font: {
                color: label.fontColor,
                family: label.fontFamily,
                size: label.size,
            },
        },
        font: { size: fontSize },
        margin,
        xaxis: {
            type: 'category',
            zeroline: false,
            fixedrange: true,
            tickangle: 'auto',
            autotickangles: [0, -45],
            ticklen: 0,
        },
        yaxis: {
            showgrid: true,
            mirror: 'ticks',
            fixedrange: true,
            showtickprefix: 'none',
            zerolinecolor: zeroLineColor,
            gridcolor: tickColor,
            ticks: 'inside',
            tickcolor: tickColor,
            tickfont: tickFont,
        },
    };

    const chartData = getChartData();

    return (
        <>
            <Plot
                onInitialized={chartUtils.setCursorByDiv(chartDiv, 'crosshair')}
                onHover={onClick ? chartUtils.setCursor('pointer') : undefined}
                onUnhover={onClick ? chartUtils.setCursor('crosshair') : undefined}
                divId={chartDiv}
                onClick={onBarClick}
                data={chartData}
                layout={chartLayout}
                config={mainConfig}
            />
            <div className="agenda">
                <div className="agenda-item new-issue">{ChartLegends[0]}</div>
                <div className="agenda-item refinancing">{ChartLegends[1]}</div>
                <div className="agenda-item reset">{ChartLegends[2]}</div>
            </div>
            <div className="description">All statistics are calculated based on the transaction pricing date.</div>
        </>
    );
}

CloManagerArrangerStatsBarChart.defaultProps = {
    chartDiv: 'clo-manager-bars-chart',
};
